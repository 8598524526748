<template>
  <v-container class="py-0 px-0">
    <div class="position-relative"
      :style="`background: url(${require('@/assets/img/lottery-area.jpg')}) center center fixed; background-size: cover; height: 100vh;`">
      <v-row no-gutters class="mx-5">
        <v-col cols="12">
          <v-card :style="`background-color: transparent!important;`">
            <div class="px-4 py-4">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card :style="`background-color: transparent!important;`">
                    <h3 class="text-h3 text-typo text-center justify-center font-weight-bold">各期獎號及開獎結果</h3>
                    <p class="text-typo text-center justify-center font-weight-bold" style="font-size:12px">Draw Results and Winning Numbers</p>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card :style="`background-color: transparent!important;`">
                    <v-card-title class="text-h6 text-typo justify-center font-weight-bold">請選擇遊戲</v-card-title>
                    <hr class="horizontal mb-4 font-weight-bold" :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
                      style="color:#1A237E;background-color:#1A237E" />
                  </v-card>
                </v-col>
                <v-col cols="12" v-for="card in cards" :key="card.title" lg="3" md="6">
                  <v-card :style="`background-color: transparent!important;`">
                    <div class="position-relative my-2 px-5">
                      <router-link :to="card.nextPageLink" class="d-block shadow-xl border-radius-xl">
                        <v-img :src="card.image" class="shadow border-radius-xl"></v-img>
                      </router-link>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Cookie from "js-cookie";
import { bingoData } from "@/apis/lotteryHistoryService";

export default {
  name: "LotteryArea",
  data() {
    return {
      list: [],
      cards: [
        {
          image: require("@/assets/img/bingobingo.png"),
          nextPageLink: "/lottery-area/bingo-bingo",
        },
        {
          image: require("@/assets/img/m539.png"),
          nextPageLink: "/lottery-area/m539",
        },
      ],
    };
  },
  mounted() {
    const token = Cookie.get('auth')
    const tokenParts = token.split('.')
    const body = JSON.parse(atob(tokenParts[1]))
    console.log(body)
    console.log(body.sub)

    this.userId = body.sub

    const data = {
      'offset': 0,
      'size': 20,
    };

    bingoData(data)
      .then(response => {
        this.repsData = response.data;
        console.log(this.repsData)
        this.list = this.repsData.list

      })
      .catch(error => { // 请求失败处理
        console.log(error);
      });

  },
};
</script>
